<template>
  <kurcc-group-inspection-list/>
</template>

<script>

export default {
  name: 'KurccGroupsPage',
  components: {
    KurccGroupInspectionList: () => import('@/modules/groups/components/KurccGroupInspectionList')
  }
}
</script>
